import { Action as ReduxAction, Dispatch } from 'redux';
const contributableLocales = require('../../../locales/contributable.json') as string[];
import StateTree from './tree';

const CACHE_SET_COUNT = 10;

export namespace Recognitions {
  export interface Recognition {
    id: string;
    text: string;
  }

  export interface State {
    [locale: string]: Recognition[];
  }

  const localeRecognitions = ({ locale, recognitions }: StateTree) =>
      recognitions[locale];

  enum ActionType {
    REFILLR = 'REFILL_RECOGNITIONS',
    REMOVER = 'REMOVE_RECOGNITIONS',
  }

  interface RefillAction extends ReduxAction {
    type: ActionType.REFILLR;
    recognitions: Recognition[];
  }

  interface RemoveAction extends ReduxAction {
    type: ActionType.REMOVER;
      recognitionIds: string[];
  }

  export type Action = RefillAction | RemoveAction;

  export const actions = {
    refill: () => async (
      dispatch: Dispatch<RefillAction>,
      getState: () => StateTree
    ) => {
      try {
        const state = getState();
        if (Object.keys(localeRecognitions(state)).length >= CACHE_SET_COUNT) {
          return;
        }

        const newRecognitions = [{"id":"0", "text":"Test-Slot 1"},{"id":"1", "text":"Test-Slot 2"},{"id":"2", "text":"Test-Slot 3"},{"id":"3", "text":"Test-Slot 4"},{"id":"4", "text":"Test-Slot 5"}]
        /*const newSentences = await state.api.fetchRandomSentences(
          CACHE_SET_COUNT
        );*/
        dispatch({
          type: ActionType.REFILLR,
          recognitions: newRecognitions,
        });
      } catch (err) {
        console.error('could not fetch sentences', err);
      }
    },

    remove: (recognitionIds: string[]) => async (
      dispatch: Dispatch<RemoveAction | RefillAction>,
      getState: () => StateTree
    ) => {
      dispatch({ type: ActionType.REMOVER, recognitionIds });
      actions.refill()(dispatch, getState);
    },
  };

  export function reducer(
    locale: string,
    state: State = contributableLocales.reduce(
      (state, locale) => ({
        ...state,
        [locale]: [],
      }),
      {}
    ),
    action: Action
  ): State {
    const localeState = state[locale];

    switch (action.type) {

      case ActionType.REFILLR:
        const recognitionIds = localeState
          .map(s => s.id)
          .concat(localeState.map(s => s.id));
        return {
          ...state,
          [locale]: localeState.concat(
            action.recognitions.filter(({ id }) => !recognitionIds.includes(id))
          ),
        };

      case ActionType.REMOVER:
        return {
          ...state,
          [locale]: localeState.filter(s => !action.recognitionIds.includes(s.id)),
        };

      default:
        return state;
    }
  }

  export const selectors = {
    localeRecognitions,
  };
}
