import * as React from 'react';

export default (props: { reverse?: boolean }) => {
  const imgSrc = props.reverse
    ? require('./kenbunLogoWhite.png') // for black background
    : require('./kenbunLogo.svg'); // for white background

  return (
    <KidouLink className="main-logo">
      <img className="main-mozilla-logo" src={imgSrc}/>
    </KidouLink>

    // <LocaleLink className="main-logo" to="">
    //   <img className="main-mozilla-logo" src={imgSrc} />
    // </LocaleLink>
  );
};

import {LocaleLink} from '../locale-helpers';
import {KenbunLink} from "../shared/links";
import {KidouLink} from "../shared/links";
