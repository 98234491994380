import { Action as ReduxAction, Dispatch } from 'redux';
const contributableLocales = require('../../../locales/contributable.json') as string[];
import StateTree from './tree';
import { User } from './user';

const MIN_CACHE_SIZE = 10;

export namespace S2TClips {
  export interface S2TClip {
    id: string;
    glob: string;
    sentence: string;
    audioSrc: string;
  }

  export interface State {
    [locale: string]: {
      s2tclips: S2TClip[];
      isLoading: boolean;
      next?: S2TClip;
    };
  }

  const localeClips = ({ locale, s2tclips }: StateTree) => s2tclips[locale];

  export const selectors = { localeClips };

  enum ActionType {
    S2TREFILL_CACHE = 'REFILL_S2TCLIPS_CACHE',
    S2TREMOVE_CLIP = 'REMOVE_S2TCLIP',
    S2TLOAD = 'LOAD_S2TCLIPS',
  }

  interface LoadAction extends ReduxAction {
    type: ActionType.S2TLOAD;
  }

  interface RefillCacheAction extends ReduxAction {
    type: ActionType.S2TREFILL_CACHE;
    s2tclips?: S2TClip[];
  }

  interface RemoveClipAction extends ReduxAction {
    type: ActionType.S2TREMOVE_CLIP;
    clipId: string;
  }

  export type Action = LoadAction | RefillCacheAction | RemoveClipAction;

  export const actions = {
    refillCache: () => async (
      dispatch: Dispatch<RefillCacheAction | LoadAction>,
      getState: () => StateTree
    ) => {
      const state = getState();
      if (localeClips(state).s2tclips.length > MIN_CACHE_SIZE) {
        return;
      }

      try {
        dispatch({ type: ActionType.S2TLOAD });

        //const s2tclips = await state.api.fetchRandomClips(MIN_CACHE_SIZE);

        const s2tclips = [{"id":"0", "glob":"glob", "text":"Test-Slot 1", "sound":""},
            {"id":"1", "glob":"glob", "text":"Test-Slot 2", "sound":""},
            {"id":"2", "glob":"glob", "text":"Test-Slot 3", "sound":""},
            {"id":"3", "glob":"glob", "text":"Test-Slot 4", "sound":""},
            {"id":"4", "glob":"glob", "text":"Test-Slot 5", "sound":""},
        ]

        dispatch({
          type: ActionType.S2TREFILL_CACHE,
          s2tclips: s2tclips.map(s2tclip => {
            let sentence;
            try {
              sentence = decodeURIComponent(s2tclip.text);
            } catch (e) {
              if (e.name !== 'URIError') {
                throw e;
              }
              sentence = s2tclip.text;
            }

            return {
              id: s2tclip.id,
              glob: s2tclip.glob,
              sentence,
              audioSrc: s2tclip.sound,
            };
          }),
        });
        //await Promise.all(s2tclips.map(({ sound }) => fetch(sound)));
      } catch (err) {
        if (err instanceof XMLHttpRequest) {
          dispatch({ type: ActionType.S2TREFILL_CACHE });
        } else {
          throw err;
        }
      }
    },

    vote: (isValid: boolean, clipId?: string) => async (
      dispatch: Dispatch<Action | User.Action>,
      getState: () => StateTree
    ) => {
      const state = getState();
      const id = clipId || localeClips(state).next.id;
      dispatch({ type: ActionType.S2TREMOVE_CLIP, clipId: id });
      await state.api.saveVote(id, isValid);
      if (!state.user.account) {
        dispatch(User.actions.tallyVerification());
      }
      User.actions.refresh()(dispatch, getState);
      actions.refillCache()(dispatch, getState);
    },

    remove: (clipId: string) => async (
      dispatch: Dispatch<Action>,
      getState: () => StateTree
    ) => {
      dispatch({ type: ActionType.S2TREMOVE_CLIP, clipId });
      actions.refillCache()(dispatch, getState);
    },
  };

  export function reducer(
    locale: string,
    state: State = contributableLocales.reduce(
      (state, locale) => ({
        ...state,
        [locale]: {
          s2tclips: [],
          next: null,
          isLoading: false,
        },
      }),
      {}
    ),
    action: Action
  ): State {
    const localeState = state[locale];

    switch (action.type) {
      case ActionType.S2TLOAD:
        return {
          ...state,
          [locale]: {
            ...localeState,
            isLoading: true,
          },
        };

      case ActionType.S2TREFILL_CACHE: {
        const s2tclips = action.s2tclips
          ? localeState.s2tclips.concat(action.s2tclips)
          : localeState.s2tclips;
        const next = localeState.next; // || s2tclips.shift();
        localeState.s2tclips = action.s2tclips;

        return {
            ...state,
            [locale]: {
                s2tclips: localeState.s2tclips,
                isLoading: false,
                next,
            }
              /*
                s2tclips: s2tclips.filter(
                    (clip1, i) =>
                        s2tclips.findIndex(clip2 => clip2.id === clip1.id) === i
                ),
                isLoading: false,
                next,
            },*/
        };

      }

      case ActionType.S2TREMOVE_CLIP: {
        const s2tclips = localeState.s2tclips.filter(c => c.id !== action.clipId);
        const next = s2tclips.pop();
        return { ...state, [locale]: { ...localeState, s2tclips, next } };
      }

      default:
        return state;
    }
  }
}
