import { TypedUseSelectorHook, useSelector } from 'react-redux';
import API from '../services/api';
import { Clips } from './clips';
import { S2TClips } from './s2tclips';
import { Flags } from './flags';
import { Notifications } from './notifications';
import { Sentences } from './sentences';
import { RequestedLanguages } from './requested-languages';
import { Uploads } from './uploads';
import { User } from './user';
import { Recognitions } from './recognitions';

export default interface StateTree {
  api: API;
  clips: Clips.State;
  flags: Flags.State;
  notifications: Notifications.State;
  locale: string;
  requestedLanguages: RequestedLanguages.State;
  sentences: Sentences.State;
  recognitions: Recognitions.State;
  uploads: Uploads.State;
  user: User.State;
  s2tclips: S2TClips.State;
}

export const useTypedSelector: TypedUseSelectorHook<StateTree> = useSelector;
