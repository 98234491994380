import * as React from 'react';
import { useState } from 'react';
import { TextButton } from '../ui/ui';
import URLS from '../../urls';
import { trackGlobal } from '../../services/tracker';
import ContactModal from '../contact-modal/contact-modal';
import { useLocale } from '../locale-helpers';

interface SharedLinkProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  dispatch?: any;
}

export const KidouLink = ({ dispatch, ...props }: SharedLinkProps) => {
  return <a href="/" {...props} />;
};

export const KenbunLink = ({ dispatch, ...props }: SharedLinkProps) => {
  return <a href="https://www.kenbun.de" {...props} />;
};

export const GitHubLink = ({ dispatch, ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  return (
    <a
      target="_blank"
      href={`${URLS.GITHUB_ROOT}`}
      rel="noopener noreferrer"
      onClick={() => trackGlobal('github', locale)}
      {...props}
    />
  );
};

export const MatrixLink = ({ dispatch, ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  return (
    <a
      target="blank"
      href="https://chat.mozilla.org/#/room/#common-voice:mozilla.org"
      onClick={() => trackGlobal('matrix', locale)}
      {...props}
    />
  );
};

export const ContactLink = ({ dispatch, ...props }: SharedLinkProps) => {
  const [locale] = useLocale();
  const [showContactModal, setShowContactModal] = useState(false);

  return (
    <>
      {showContactModal && (
        <ContactModal onRequestClose={() => setShowContactModal(false)} />
      )}

      <TextButton
        {...props}
        onClick={() => {
          trackGlobal('contact', locale);
          setShowContactModal(true);
        }}
      />
    </>
  );
};
